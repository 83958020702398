module.exports = {
  local: {
    baseUrl: "http://yeyuan8.com/api",
    mockUrl: "http://127.0.0.1:7001",
  },
  development: {
    baseUrl: "http://yeyuan8.com/api",
    mockUrl: "http://localhost:8085",
  },
  production: {
    baseUrl: "http://yeyuan8.com/api",
    mockUrl: "http://yeyuan8.com/api",
  },
  publish: {
    baseUrl: "http://yeyuan8.com/api",
    mockUrl: "http://yeyuan8.com/api",
  },
};

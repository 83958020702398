import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("@/views/index"),
    meta: {
      title: "首页",
      showTab: true,
    },
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/index"),
    meta: {
      title: "首页",
      showTab: true,
    },
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import("@/views/detail"),
    meta: {
      title: "详情",
      showTab: true,
      keepAlive: true,
    },
  },
  {
    path: "/searchResult",
    component: () => import("@/views/searchResult"),
    meta: {
      title: "搜索页",
    },
  },
  {
    path: "/cateResult",
    component: () => import("@/views/cateResult"),
    meta: {
      title: "分类结果页",
    },
  },
  {
    path: "/signin",
    name: "Login",
    component: () => import("@/views/login/signIn"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/signup",
    name: "SingUp",
    component: () => import("@/views/login/signUp"),
    meta: {
      title: "注册",
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
